.recomHomeContainer{
    width: 100%;
    height: calc(100vh - 60px);
}
.recomHomeContainer > .mapRegistWarp{
    width: 75%;
    height: calc(100% - 60px);
    position: absolute;
    right: 5%;
    bottom: 0;
}
.recomHomeContainer > .recomInfoWarp{
    width: 20%;
    height: calc(100% - 60px);
    position: absolute;
    left: 0;
    bottom: 0;
}
.recomHomeContainer > .recomInfoWarp > div{
    margin: 0;
    width: 100%;
}