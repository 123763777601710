.recomModalContainer{
    width: 530px;
    height: auto;
    margin: 0 auto;
    position: absolute;
    top: 50vh;
    left: 50vw;
    z-index: 2;
    
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #000;
}

.recomModalContainer .dateContainer > input{
    width: 130px;
}
.recomModalContainer > div{
    padding: 20px;
}
.recomModalContainer > div > div{
    margin-bottom: 10px;
    font-size: 16px;
}
.recomModalContainer > div > div > span{
    display: inline-block;
    width: 120px;
}
.recomModalContainer > div > div > input{
    width: 280px;
    height: 25px;
    border: 0.5px solid #000
}

.recomModalContainer > div > div:nth-child(2) > input{
    width: 280px;
}

/* info 창 */
.recomModalContainer  > ul{
    border-top: 1px solid #000;
    padding: 20px;
    padding-bottom: 0;
}
.recomModalContainer  > ul > li {
    margin-bottom: 10px;
}
.recomModalContainer  > ul > li > span{
    display: inline-block;
    width: 120px;
}
.recomModalContainer  > ul > li > input{
    width: 280px;
    height: 25px;
    border: 0.5px solid #000
}
/* 버튼들 */
.recomModalContainer > button:nth-child(1) {
    position: absolute;
    right: 0;
    top: 0;

    width: 40px;
    height: auto;
    font-size: 40px;
    background: none;
    border: none;
}
.recomModalContainer .imageSearchBtn{
    height: 23px;
    background: #fff;
    border: 1px solid #000;
    margin-left: 5px;
}
.recomModalContainer > button:nth-child(3),
.recomModalContainer > button:nth-child(4){
    width: 160px;
    height: 40px;
    float: right;
    margin:10px 20px 10px 0;
    background: #fff;
    border: 1px solid #000;
} 
.recomModalContainer > button:nth-child(4){
    position: relative;
    right: 50%;
    transform: translateX(50%);
}

.imageUpload{
    display: none;
}
.fileImg{
    display: block;
    width: 300px;
    height: 200px;
    margin: 0 auto;
}

/* 일반매물 추천매물 선택 */
.saleToggle{width: 100%;}
.saleToggle > div > input {
    display: inline-block;
    position: relative;
    top: 7px;
    left: 20px;
    width: 20px;
    height: 25px;
}
.saleToggle > div {
    width: 50%;
    display: inline-block;
}