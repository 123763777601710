.sendBoardContainer{
    height: 700px;
    width: 500px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 2;
}

/* 테이블 새로고침, 닫기버튼 */
.sendBoardContainer > button {
    position: absolute;
    width: 60px;
    height: 30px;
    font-size: 16px;
    border: none;
    background-color: rgb(255,255,255,0.3);
    top: 0;
    right: 0;
}
.sendBoardContainer > div {
    height: 40px;
}
.sendBoardContainer > div > button{
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 10px;
}

/* 타이틀 */
.sendBoardContainer > h2{
    text-align: center;
    margin-bottom: 3px;
}
/* 검색창 */
.sendBoardContainer > section > label{
    width: 20%;
    line-height: 28px;
    padding: 0 5px;
}
.sendBoardContainer > section > input{
    width: 80%;
    line-height: 28px;
    padding: 0 5px;
}

/* 테이블 일부로 container안씀, 전체 회원 페이지와 modal 스타일 통일*/
.sendTable {
    border-collapse: collapse;
    margin-top: 10px;
    width: 100%;
}

.sendTable > thead tr th , .sendTable > tbody tr td{
    border: none;
    text-align: left;    
}

.sendTable > tbody tr td{
    cursor: pointer;  
}

.sendTable > tbody tr{
    height: 30px;
    font-size: 12px;
    margin-top: 2px;
}

.sendTable > tbody tr:hover{
    background-color: skyblue;
}


/* 
        전체 회원 css
*/

.sendAllContainer {
    width: 50%;
    margin: 0 auto;
}
.sendAllContainer > h2{
    padding: 30px;
    text-align: center;
}
/* 새로고침 */
.sendAllContainer > div {
    position: absolute;
    top: 60px;
    left: 0;
}

/* 검색창 */
.sendAllContainer > section > label{
    width: 20%;
    text-align: left;
    line-height: 28px;
    padding: 0px 5px;
    font-weight: bold;
}
.sendAllContainer > section {
    width: 80%;
    margin: 0 auto;
    height: 32px;
}
.sendAllContainer > section > input{
    float: right;
    width: 80%;
    line-height: 28px;
    padding: 0 5px;
}

.sendAllContainer > .sendTable {
    border-collapse: collapse;
    margin: 20px auto;
    width: 70%;
}
.test{
    color: skyblue;
}