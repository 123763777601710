.messageContainer{
    margin-top: 75px;
}
.messageContainer > form > ul {
    width: 300px;
    margin: 0 auto;
}
.messageContainer > form > ul > li {
    width: 300px;
    padding: 5px;
    margin: 0 auto;
}
.messageContainer > form > ul > li > h3{
    text-align: center;
}

.messageContainer > form > ul > li > label {
    display: block;
}
.messageContainer > form > ul > li > input {
    width: 100%;
    height: 28px;
}
.messageContainer > form > ul > li > textarea {
    width: 100%;
    height: 120px;
    resize: none;
}
.messageContainer > form > ul > li > button{
    width: 100%;
    height: 41px;
    padding: 10px;
    border: 1px solid #000;
} 
.messageContainer > form > ul > li:nth-child(6) > button{
    background-color: #f2f2f2;
}
.messageContainer > form > ul > li:nth-child(8) > button,
.messageContainer > form > ul > li:nth-child(10) > button{
    background-color: #fff;
}