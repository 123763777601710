.registContainer{
    width: 80%;
    height: auto;
    margin: 0px auto;
}
.registContainer > h2{
    width: 15%;
    margin: 30px 0;
    float: left;
    text-align: right;
}
.registContainer > div{
    width: 85%;
    float: right;
    position: relative;
    margin: 30px 0;
}
.registContainer > div > ul{
    width: 80%;
    margin: 0 auto;
}
.registContainer > div > ul > div{
    display: inline-block;
    width: 24%;
    border: 1px solid #000;
    margin-right: 1%;
    box-sizing: border-box;
}

/* 성격, 마케팅 문구 */

.registContainer > div > ul > div > li:nth-child(1){
    border-bottom: 1px solid #000;
    text-align: center;
}
.registContainer > div > ul > div > li:nth-child(1) > span:nth-child(1){
    display: inline-block;
    width: 40%;
    height: 50px;
}
.registContainer > div > ul > div > li:nth-child(1) > span:nth-child(2){
    display: inline-block;
    width: 60%;
    height: 50px;
}

.registContainer > div > ul > div > li{
    margin-bottom: 8px;
}
.registContainer > div > ul > div > div > button{
    
    cursor: pointer;
    width: 45%;
    height: 38px;
    background: rgb(114, 203, 255); 
    border: 1px solid #D9DDE2;
    
    color: azure;
    font-size: 14px;
    line-height: 12px;
    margin: 1%;
}
.registContainer > div > ul > div > div > button:nth-child(1){
    float: left;
}
.registContainer > div > ul > div > div > button:nth-child(2){
    float: right;
}

/* allow */

.registContainer > div > .registLeft,
.registContainer > div > .registRight{
    display: inline-block;
    position: absolute;
    background: none;
    border: none;
    font-size: 30px;
    vertical-align: middle;
    height: 200px;
    width: 8%;
    top: 0;
}
.registContainer > div > .registLeft{
    
    left: 0;
}
.registContainer > div > .registRight{
    right: 0;
}