.infoWindowContainer{
    width: 100%;
    height: calc(100vh - 60px);
    position: relative;
}
/* 정보창 */
.infoWindowContainer > .leftInfo {
    width: 270px;
    height: 100%;
    border: 1px solid #000;
    border-top: none;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff;
    z-index: 1;
}
.rightInfoNone{
    display: none;
}
.infoWindowContainer > .leftInfo >ul{
    padding: 20px;
    margin-top: 50px;
}
.infoWindowContainer > .leftInfo > ul >li{
    height: 50px;
}
/* 검색 목록 */
.infoWindowContainer > .rightInfo {
    position: absolute;
    width: 300px;
    left: 270px;
    top: 0;
    height: 100%;
}

.infoWindowContainer > .rightInfo > ul{
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    border-left: none;
    border-top: none;
    overflow-y: scroll;
    -ms-overflow-style: none;
    z-index: 1;
    background: #fff;
}
.infoWindowContainer > .rightInfo > ul::-webkit-scrollbar{ display:none; }
.infoWindowContainer > .rightInfo > ul >li{
    height: 80px;
    width: auto;
    margin: 10px 0;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding: 5px;

}
.infoWindowContainer > .rightInfo > ul >li > span{
    display: inline-block;
    width: 100%;
}
.infoWindowContainer > .rightInfo > button:nth-child(1){
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: #ccc;
    z-index: 1;
} 
.infoWindowContainer > .rightInfo > button:nth-child(2) {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: calc(100% + 14px);
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background: #ccc;
    z-index: 1;
}

.infoSendBtn{
    display: inline-block;
    width: 200px;
    height: 30px;
    margin: 20px
}