* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

ul, ol, li {
  list-style: none;
}