.emailContainer{
    margin-top: 75px;
}
.emailContainer > form > ul {
    width: 300px;
    margin: 0 auto;
}
.emailContainer > form > ul > li {
    width: 300px;
    padding: 5px;
    margin: 0 auto;
}
.emailContainer > form > ul > li > h3{
    text-align: center;
}

.emailContainer > form > ul > li > label {
    display: block;
}
.emailContainer > form > ul > li > input {
    width: 100%;
    height: 28px;
}
.emailContainer > form > ul > li > textarea {
    width: 100%;
    height: 120px;
    resize: none;
}
.emailContainer > form > ul > li > button{
    width: 100%;
    height: 41px;
    padding: 10px;
    border: 1px solid #000;
} 
.emailContainer > form > ul > li:nth-child(7) > button{
    background-color: #f2f2f2;
}
.emailContainer > form > ul > li:nth-child(9) > button,
.emailContainer > form > ul > li:nth-child(11) > button{
    background-color: #fff;
}